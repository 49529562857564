import React from 'react';
import { useIsMobile } from '../hooks/useIsMobile';
import { color } from '../theme/color';
import { addURLPrefix } from '../utils/addURLPrefix';
import { extractWithLocale } from '../utils/extractWithLocale';
import { ArticleTeaser } from './ArticleTeaser';
import { useLocale } from './CurrentLocale';
import { LatestPostTeaser } from './LatestPostTeaser';
import { AnyItem } from './sections/LatestPostsSection';
import { Text } from './common/Text';

type LatestPostItemProps = {
  index: number;
  isGrid: boolean;
  typeName: string;
  jobDefaultImage: any;
  item: AnyItem;
};

export const LatestPostItem: React.FC<LatestPostItemProps> = ({
  index,
  isGrid,
  typeName,
  jobDefaultImage,
  item,
}) => {
  const isMobile = useIsMobile();
  const locale = useLocale();

  const isJob = item.__typename === 'TeamtailorJob';
  const isCase = item.__typename === 'SanityCase';

  const title = isJob
    ? item.heading || ''
    : extractWithLocale(item.teaser?.teaserHeading, locale) ||
      extractWithLocale(item.heading, locale);
  const preamble = isJob ? '' : extractWithLocale(item.teaser?.teaserPreamble, locale);

  let url;
  if (isJob) {
    const jobUrl = item.links.careersite_job_url;
    const jobUrlIndex = (jobUrl && jobUrl.lastIndexOf('/')) || 0;
    url = (locale === 'sv' ? '/jobb/' : '/jobs/') + (jobUrl && jobUrl.slice(jobUrlIndex + 1));
  } else {
    const slug = extractWithLocale(item.slug, locale)?.current || null;
    url = addURLPrefix(slug, getUrlPrefix(item.__typename), locale);
  }

  if (isJob && !isGrid) {
    return (
      <ArticleTeaser
        key={'article-teaser-' + item.id}
        title={title || ''}
        kicker={item?.locations[0]?.city || 'undefined'}
        link={url}
        index={index}
        gridColumn="auto"
        mb={0}
        forceSquare={true}
        animateInColumns={true}
        bg="rgba(213,213,213, 0.5)"
        imageAltText=""
        eventDate={null}
      />
    );
  }

  const fluidImage =
    item?.teaser?.teaserImage?.asset?.gatsbyImageData ||
    item?.image?.asset?.gatsbyImageData ||
    null;
  const imageAltText = extractWithLocale(item.imageAltText, locale);

  const trackLength = item.embeddedVideo?.videoLength || item.embeddedAudio?.length;
  const teaserType = item.embeddedAudio?.url ? 'audio' : 'video';

  return (
    <LatestPostTeaser
      key={'latest-post-teaser-' + item.id}
      kicker={
        isCase ? (
          <Text fontSize={[1, 2]} fontWeight={[300]} mt={[3]} mb={[1]} style={{ display: 'inline-flex' }}>
            {item.client?.name}
          </Text>
        ) : (
          typeName
        )
      }
      title={title || ''}
      preamble={index % 8 === 0 ? preamble : null}
      fluidImage={isJob ? jobDefaultImage : fluidImage}
      imageAltText={imageAltText}
      link={url}
      index={index}
      trackLength={trackLength}
      type={teaserType}
      gridColumn={isGrid && !isMobile ? itemColumns(index) : ['span 4', 'span 4', 'auto', 'auto']}
      gridRow={(isGrid && !isMobile && itemRows(index)) || undefined}
      padding={(index % 8 === 0 && isGrid && 4) || 0}
      backgroundColor={
        (index % 16 === 0 && isGrid && color.pink) ||
        (index % 16 === 8 && isGrid && '#eee') ||
        undefined
      }
      teaserCategory={item.__typename}
    />
  );
};

const getUrlPrefix = (type: string) => {
  switch (type) {
    case 'SanityCase':
      return 'case';
    case 'SanityArticle':
      return 'article';
    case 'TeamtailorJob':
      return 'page';
    case 'SanityPlay':
    default:
      return 'esatto-play';
  }
};

let itemColumns = (index: number) => {
  switch (index % 16) {
    case 0:
      return '1 / -3';
    case 1:
    case 2:
      return '-3 / -1';
    case 3:
    case 11:
      return '1 / -4';
    case 4:
      return '-4 / -1';
    case 5:
    case 9:
    case 10:
    case 13:
      return '1 / 3';
    case 6:
    case 14:
      return '3 / 5';
    case 7:
    case 15:
      return '5 / -1';
    case 8:
      return '3 / -1';
    case 12:
      return '4 / -1';
    default:
      return 'auto';
  }
};

let itemRows = (index: number) => {
  let count = Math.floor(index / 8) * 4 + 1;

  if (index % 8 === 0) {
    let start = count;
    let end = count + 2;
    return start + ' / ' + end;
  } else if (index % 8 === 1) {
    let start = count;
    let end = count + 1;
    return start + ' / ' + end;
  } else if (index % 8 === 2) {
    let start = count + 1;
    let end = count + 2;
    return start + ' / ' + end;
  }

  return 'auto';
};
