import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useCategoryMenu } from '../../hooks/useCategoryMenu';
import { useIsMobile } from '../../hooks/useIsMobile';
import { color } from '../../theme/color';
import { bodyPadding } from '../../theme/theme';
import { addURLPrefix } from '../../utils/addURLPrefix';
import { extractWithLocale } from '../../utils/extractWithLocale';
import {
  PlaysQuery,
  PlaysQuery_plays_nodes,
  Sections_SanityPlayListSection,
} from '../../__generated__/graphql';
import { CategoriesMenu } from '../CategoriesMenu';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { useLocale } from '../CurrentLocale';
import { PlayTeaser } from '../PlayTeaser';

export type PlayListSectionProps = {
  section: Sections_SanityPlayListSection;
};

export const PlayListSection: React.FC<PlayListSectionProps> = ({ section }) => {
  const [selected, setSelected] = useCategoryMenu('esatto-play');
  const data = useStaticQuery<PlaysQuery>(query);
  const locale = useLocale();
  const isMobile = useIsMobile();

  const categories = data.categories.nodes;
  const plays = data.plays.nodes;

  let filtered = plays.reduce((acc, play) => {
    if (selected && !play.categories?.some(p => p?.title?.toLowerCase() === selected)) {
      return acc;
    }

    return [...acc, play];
  }, [] as PlaysQuery_plays_nodes[]);

  return (
    <>
      <Box as="section" gridColumn="2 / -2" px={bodyPadding} pb={7}>
        <CategoriesMenu
          categories={categories}
          selected={selected}
          onChange={setSelected}
          onCategoryName={title => '#' + title}
        />
        {isMobile && <Box gridColumn="1 / -1" mb={3} borderBottom="1px solid" />}

        <InnerGrid columns={[1, !selected ? 6 : 3]} gridGap={4}>
          {filtered.map((item, index) =>
            renderPlay(index, isMobile, filtered.length === plays.length, locale, item, !selected)
          )}
        </InnerGrid>
      </Box>
    </>
  );
};

const renderPlay = (
  index: number,
  isMobile: boolean,
  grid: boolean,
  locale: 'sv' | 'en' | 'dk',
  item: PlaysQuery_plays_nodes,
  gridOrder: boolean
) => {
  const title =
    extractWithLocale(item.teaser?.teaserHeading, locale) || extractWithLocale(item.name, locale);
  const slug = extractWithLocale(item.slug, locale)?.current ?? null;
  const preamble = extractWithLocale(item.teaser?.teaserPreamble, locale);

  const category = (item.categories && item.categories[0]?.title) || null;
  const imageAltText = extractWithLocale(item.teaser?.imageAltText || item.imageAltText, locale);

  const image =
    (item.teaser?.featuredTeaserImage || item.featuredImage)?.asset?.gatsbyImageData ?? null;
  const url = addURLPrefix(slug, 'esatto-play', locale);

  let itemColumns = (index: number) => {
    switch (index % 16) {
      case 0:
        return '1 / -3';
      case 1:
      case 2:
        return '-3 / -1';
      case 3:
      case 11:
        return '1 / -4';
      case 4:
        return '-4 / -1';
      case 5:
      case 9:
      case 10:
      case 13:
        return '1 / 3';
      case 6:
      case 14:
        return '3 / 5';
      case 7:
      case 15:
        return '5 / -1';
      case 8:
        return '3 / -1';
      case 12:
        return '4 / -1';
      default:
        return 'auto';
    }
  };

  let itemRows = (index: number) => {
    let count = Math.floor(index / 8) * 4 + 1;

    if (index % 8 === 0) {
      let start = count;
      let end = count + 2;
      return start + ' / ' + end;
    } else if (index % 8 === 1) {
      let start = count;
      let end = count + 1;
      return start + ' / ' + end;
    } else if (index % 8 === 2) {
      let start = count + 1;
      let end = count + 2;
      return start + ' / ' + end;
    }

    return 'auto';
  };

  const teaserType = item.embeddedAudio?.url ? 'audio' : 'video';
  const trackLength = item.embeddedVideo?.videoLength || item.embeddedAudio?.length;

  return (
    <PlayTeaser
      kicker={category}
      title={title}
      preamble={index % 8 === 0 ? preamble : null}
      fluidImage={image}
      imageAltText={imageAltText}
      link={url}
      index={index}
      type={teaserType}
      trackLength={trackLength}
      gridColumn={
        gridOrder && !isMobile ? itemColumns(index) : ['span 4', 'span 4', 'auto', 'auto']
      }
      gridRow={gridOrder && !isMobile && itemRows(index)}
      padding={index % 8 === 0 && grid && 4}
      backgroundColor={
        (index % 16 === 0 && grid && color.pink) ||
        (index % 16 === 8 && grid && color.white) ||
        null
      }
    />
  );
};

const query = graphql`
  query PlaysQuery {
    plays: allSanityPlay(
      filter: { isVisibleOnList: { ne: false } }
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        ...PlayTeaser
      }
    }
    categories: allSanityPlayCategory {
      nodes {
        title
      }
    }
  }

  fragment PlayTeaser on SanityPlay {
    __typename
    id
    teaser {
      teaserHeading {
        sv
        en
        dk
      }
      teaserPreamble {
        sv
        en
        dk
      }
      imageAltText {
        sv
        en
        dk
      }
      featuredTeaserImage: teaserImage {
        asset {
          gatsbyImageData(width: 800, height: 480)
        }
      }
    }
    slug {
      en {
        current
      }
      sv {
        current
      }
      dk {
        current
      }
    }
    name {
      sv
      en
      dk
    }
    embeddedVideo {
      videoLength
    }
    embeddedAudio {
      url
      length
    }
    featuredImage: image {
      asset {
        gatsbyImageData(width: 800, height: 480)
      }
    }
    imageAltText {
      sv
      en
      dk
    }
    categories {
      id
      title
    }
  }
`;
