import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useCategoryMenu } from '../../hooks/useCategoryMenu';
import { bodyPadding } from '../../theme/theme';
import { extractWithLocale } from '../../utils/extractWithLocale';
import {
  ArticleListQuery_categories_nodes,
  LatestPostsQuery,
  LatestPostsQuery_articles_nodes,
  LatestPostsQuery_cases_nodes,
  LatestPostsQuery_jobs_nodes,
  LatestPostsQuery_plays_nodes,
  Sections_SanityLatestPostsSection,
  Sections_SanityLatestPostsSection_items,
} from '../../__generated__/graphql';
import { CategoriesMenu } from '../CategoriesMenu';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { useLocale } from '../CurrentLocale';
import { LatestPostItem } from '../LatestPostItem';
import { TalksTopSection } from '../top/TalksTopSection';
import { getSupportedLanguages } from '../../utils/languages';
import { filterJobsByLocation } from '../../utils/filterJobsByLocation';

export type LatestPostsSectionProps = {
  section: Sections_SanityLatestPostsSection;
};

export type AnyItem =
  | LatestPostsQuery_cases_nodes
  | LatestPostsQuery_articles_nodes
  | LatestPostsQuery_plays_nodes
  | LatestPostsQuery_jobs_nodes;

export const LatestPostsSection: React.FC<LatestPostsSectionProps> = ({ section }) => {
  const supportedLanguages = getSupportedLanguages();
  const [selected, setSelected] = useCategoryMenu('/');
  const data = useStaticQuery<LatestPostsQuery>(query);
  const locale = useLocale();

  const sectionTitle = extractWithLocale(section.heading, locale);
  const jobDefaultImage = data.config?.latestPosts?.jobsImage?.asset?.gatsbyImageData;

  const selectedType = section?.items?.find(
    i => extractWithLocale(i?.label, locale)?.toLowerCase() === selected
  )?.type;
  const selectedTypeCategory = section?.items?.find(
    i => extractWithLocale(i?.label, locale)?.toLowerCase() === selected
  )?.categories?.title;

  const allCategoryLabel = extractWithLocale(
    section.items?.find(i => i?.type === 'all')?.label,
    locale
  );
  const isGrid = selected === 'all' || (selected === null && supportedLanguages.length > 1);
  const jobs = filterJobsByLocation(data.jobs?.nodes ?? []);

  let items = jobs
    .concat(data.articles.nodes as any)
    .concat(data.cases.nodes as any)
    .concat(data.plays.nodes as any) as any[];

  let filtered = items
    .filter(item => {
      if (selectedType === 'cases') return item.__typename === 'SanityCase';
      if (selectedType === 'stuffs')
        return (
          item.__typename === 'SanityArticle' &&
          item.category?.title?.toLowerCase() === selectedTypeCategory?.toLowerCase()
        );
      if (selectedType === 'plays') return item.__typename === 'SanityPlay';
      if (selectedType === 'jobs') return item.__typename === 'TeamtailorJob';

      return true;
    })
    .filter(item => {
      if (!item) {
        return false;
      }
      if (item.__typename === 'TeamtailorJob') {
        return true;
      }

      const hasHeading = extractWithLocale(item.heading, locale) !== null;
      const hasSlug = extractWithLocale(item.slug, locale)?.current !== null;

      return hasHeading && hasSlug;
    })
    .slice(0, 13);

  return (
    <Box as="section" gridColumn="2 / -2" px={bodyPadding} pb={7}>
      {sectionTitle && (
        <Box pb={5}>
          <TalksTopSection heading={sectionTitle} preamble={' '} />
        </Box>
      )}

      {typeof section.hideNavigationBar !== 'undefined' && !section.hideNavigationBar && (
        <CategoriesMenu
          categories={getCategoriesList(
            section.items as Sections_SanityLatestPostsSection_items[],
            locale
          )}
          selected={selected}
          onChange={setSelected}
          allLabel={allCategoryLabel}
          fontSize={20}
        />
      )}

      <InnerGrid columns={[1, isGrid ? 6 : 3]} gridGap={4}>
        {filtered &&
          filtered.length &&
          filtered.map((item: AnyItem, index: number) => {
            const typeName =
              getTypeName(
                section.items as Sections_SanityLatestPostsSection_items[],
                item,
                locale
              ) || '';

            return (
              <LatestPostItem
                key={'latest-post-item-' + item.id}
                index={index}
                isGrid={isGrid}
                typeName={typeName}
                jobDefaultImage={jobDefaultImage}
                item={item}
              />
            );
          })}
      </InnerGrid>
    </Box>
  );
};

const getTypeName = (
  items: Sections_SanityLatestPostsSection_items[],
  item: AnyItem,
  locale: 'sv' | 'en' | 'dk'
) => {
  switch (item.__typename) {
    case 'SanityArticle':
      return (
        item.category?.title ||
        extractWithLocale(items?.find(item => item.type === 'stuffs')?.label, locale)
      );
    case 'SanityCase':
      return extractWithLocale(items?.find(item => item.type === 'cases')?.label, locale);
    case 'SanityPlay':
      return (
        (item.category && item.category[0]?.title) ||
        extractWithLocale(items?.find(item => item.type === 'plays')?.label, locale)
      );
    case 'TeamtailorJob':
      return extractWithLocale(items?.find(item => item.type === 'jobs')?.label, locale);
  }
};

export const getUrlPrefix = (type: string) => {
  switch (type) {
    case 'SanityCase':
      return 'case';
    case 'SanityArticle':
      return 'article';
    case 'TeamtailorJob':
      return 'page';
    case 'SanityPlay':
    default:
      return 'esatto-play';
  }
};

const getCategoriesList = (
  items: Sections_SanityLatestPostsSection_items[],
  locale: 'sv' | 'en' | 'dk'
) => {
  const hidePlays = process.env.GATSBY_HIDE_PLAYS === 'true';
  const hideTalks = process.env.GATSBY_HIDE_TALKS === 'true';
  return items
    .filter(item => {
      const label = (item.label?.en + '').toLocaleLowerCase();
      if (hidePlays && label.includes('play')) {
        return false;
      }
      if (hideTalks && label.includes('talks')) {
        return false;
      }
      return true;
    })
    .filter(i => i.type !== 'all')
    .filter(i => extractWithLocale(i.label, locale) !== null)
    .map(i => {
      return {
        title: extractWithLocale(i.label, locale),
      } as ArticleListQuery_categories_nodes;
    }) as ArticleListQuery_categories_nodes[];
};

const query = graphql`
  query LatestPostsQuery {
    articles: allSanityArticle(
      sort: { fields: publishDate, order: DESC }
      filter: { isVisibleOnList: { ne: false } }
      limit: 12
    ) {
      nodes {
        __typename
        id
        heading {
          en
          sv
          dk
        }
        slug {
          en {
            current
          }
          sv {
            current
          }
          dk {
            current
          }
        }
        publishDate
        teaser: teaser {
          teaserImage {
            asset {
              gatsbyImageData(width: 800, height: 480)
            }
          }

          teaserHeading {
            sv
            en
            dk
          }
          teaserPreamble {
            sv
            en
            dk
          }
        }
        image {
          asset {
            gatsbyImageData(width: 800, height: 480)
          }
        }
        imageAltText {
          sv
          en
          dk
        }
        category {
          title
        }
      }
    }

    cases: allSanityCase(
      sort: { fields: [displayOrder, publishDate], order: [ASC, DESC] }
      filter: { isVisibleOnList: { ne: false } }
      limit: 12
    ) {
      nodes {
        __typename
        id
        heading {
          en
          sv
          dk
        }
        slug {
          en {
            current
          }
          sv {
            current
          }
          dk {
            current
          }
        }
        displayOrder
        publishDate
        client {
          name
        }
        teaser: teaser {
          teaserImage {
            asset {
              gatsbyImageData(width: 800, height: 480)
            }
          }

          teaserHeading {
            sv
            en
            dk
          }
          teaserPreamble {
            sv
            en
            dk
          }
        }
        image {
          asset {
            gatsbyImageData(width: 600, height: 375)
          }
        }
        imageAltText {
          sv
          en
          dk
        }
      }
    }

    plays: allSanityPlay(
      sort: { fields: publishDate, order: DESC }
      filter: { isVisibleOnList: { ne: false } }
      limit: 12
    ) {
      nodes {
        __typename
        id
        heading: name {
          sv
          dk
          en
        }
        slug {
          en {
            current
          }
          sv {
            current
          }
          dk {
            current
          }
        }
        publishDate
        teaser: teaser {
          teaserImage {
            asset {
              gatsbyImageData(width: 800, height: 480)
            }
          }

          teaserHeading {
            sv
            en
            dk
          }
          teaserPreamble {
            sv
            en
            dk
          }
        }
        image {
          asset {
            gatsbyImageData(width: 800, height: 480)
          }
        }
        imageAltText {
          sv
          en
          dk
        }
        embeddedVideo {
          videoLength
        }
        embeddedAudio {
          url
          length
        }
        category: categories {
          title
        }
      }
    }

    jobs: allTeamtailorJob(sort: { fields: created_at, order: DESC }, limit: 12) {
      nodes {
        __typename
        id
        heading: title
        publishDate: created_at
        links {
          careersite_job_url
        }
        locations {
          city
        }
      }
    }

    config: sanityConfig {
      latestPosts {
        jobsImage {
          asset {
            gatsbyImageData(width: 800, height: 480)
          }
        }
      }
    }
  }
`;
