import React from 'react';
import { Box } from '../common/Box';
import { InnerGrid } from '../common/InnerGrid';
import { ReactComponent as Frontend } from '../../images/icons/frontend.svg';
import { ReactComponent as Cloud } from '../../images/icons/cloud.svg';
import { ReactComponent as Mobile } from '../../images/icons/mobile.svg';
import { ReactComponent as Backend } from '../../images/icons/backend.svg';
import { ReactComponent as Cms } from '../../images/icons/cms.svg';
import { ReactComponent as Languages } from '../../images/icons/languages.svg';
import { ReactComponent as CiCd } from '../../images/icons/ci-cd.svg';
import styled from 'styled-components';
import { Text } from '../common/Text';
import { bodyPadding } from '../../theme/theme';

type CompetenceIcon = 'frontend' | 'cloud' | 'mobile' | 'backend' | 'cms' | 'languages' | 'cicd';

type Competence = {
  title: string;
  id: string;
  icon: CompetenceIcon;
  items: string[];
};

type CompetenceList = Competence[];

const COMPETENCES: CompetenceList = [
  {
    title: 'Languages',
    id: '1',
    icon: 'languages',
    items: [
      'C#',
      'JavaScript / TypeScript',
    ],
  },
  {
    title: 'Backend',
    id: '2',
    icon: 'backend',
    items: [
      'Microsoft .NET &.NET Core',
      'Node.js',
    ],
  },
  {
    title: 'Frontend',
    id: '3',
    icon: 'frontend',
    items: ['React JS / Next JS', 'Angular', 'VueJS'],
  },
  {
    title: 'CMS & Commerce',
    id: '4',
    icon: 'cms',
    items: [
      'Optimizely',
      'Umbraco',
    ],
  },
  {
    title: 'Mobile',
    id: '5',
    icon: 'mobile',
    items: ['Native iOS and Android', 'React Native'],
  },
  {
    title: 'CI / CD',
    id: '6',
    icon: 'cicd',
    items: ['Azure DevOps', 'GitHub'],
  },
  {
    title: 'Cloud & Platforms',
    id: '7',
    icon: 'cloud',
    items: [
      'Azure',
      'Optimizely DXP',
      'AWS',
      'Google Cloud',
      'Umbraco Cloud',
      'Netlify',
    ],
  },
];

const getIcon = (icon: CompetenceIcon) => {
  if(!icon) {
    return null;
  }

  switch (icon) {
    case 'frontend':
      return <Frontend />;
    case 'cloud':
      return <Cloud />;
    case 'mobile':
      return <Mobile />;
    case 'backend':
      return <Backend />;
    case 'cms':
      return <Cms />;
    case 'languages':
      return <Languages />;
    case 'cicd':
      return <CiCd />;
      default:
        return null;
  }
};

export const CompetenceListSection = () => {
  const competences = COMPETENCES;

  return (
    <Box as="section" mt={4} bg="transparent" gridColumn="2 / -2"  px={bodyPadding} pb={6}>
      <Text as="h2" fontWeight={500} fontSize={[3, 4]} mb={4} mt={0} lineHeight={1.2}>We speak tech</Text>
      <InnerGrid columns={[1, 2, 3]} gridGap={3} alignItems='start'>
        {competences.map((competence) => (
        <>
          <Box key={competence.id}>
            <Header>
              <Text as="h3" fontWeight={700} fontSize={[2, 3]} mb={0} mt={0} lineHeight={1.2}>{competence.title}</Text>
              {competence.icon && getIcon(competence.icon)}
            </Header>
            <List>
              {competence.items.map((item) => (
                <ListItem key={item}>
                <Text as="span" fontWeight={300} fontSize={[2,3]}>{item}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
        ))}
      </InnerGrid>
    </Box>
  );
};


const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.text4};
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    order: -1;
    
    @media screen and (min-width: 40em) {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const List = styled.ul`
  list-style: none ;
  padding-left: 0;
  margin: 0 0 1rem 0;
`;

const ListItem = styled.li`
  margin: 0 0 0.5rem 0;
`;