interface Location {
  city: string | null;
}

type JobLocation = Partial<Location | null>[] | null;

export const filterJobsByLocation = <TEntity extends Partial<{ locations?: JobLocation }>>(
  jobs: TEntity[]
): TEntity[] => {
  return jobs?.filter(job => {
    const onlyLocations = (process.env.GATSBY_TEAMTAILOR_ONLY_LOCATION + '').split(',');
    if (!onlyLocations || onlyLocations.length === 0) return true;

    return job?.locations?.find(loc => !!onlyLocations.find(x => x === loc?.city)) ?? false;
  }) ?? [];
};
